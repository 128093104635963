/*
  _   _      _                   _____          _                   
 | | | |_ __| |__   __ _ _ __   |  ___|_ _  ___| |_ ___  _ __ _   _ 
 | | | | '__| '_ \ / _` | '_ \  | |_ / _` |/ __| __/ _ \| '__| | | |
 | |_| | |  | |_) | (_| | | | | |  _| (_| | (__| || (_) | |  | |_| |
  \___/|_|  |_.__/ \__,_|_| |_| |_|  \__,_|\___|\__\___/|_|   \__, |
                                                              |___/ 
                     Copyright Urban Factory 2016
*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
  _   _      _                   _____          _                   
 | | | |_ __| |__   __ _ _ __   |  ___|_ _  ___| |_ ___  _ __ _   _ 
 | | | | '__| '_ \ / _` | '_ \  | |_ / _` |/ __| __/ _ \| '__| | | |
 | |_| | |  | |_) | (_| | | | | |  _| (_| | (__| || (_) | |  | |_| |
  \___/|_|  |_.__/ \__,_|_| |_| |_|  \__,_|\___|\__\___/|_|   \__, |
                                                              |___/ 
                     Copyright Urban Factory 2017
*/
.fix-margin-collapse {
  display: block; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

/*
  _   _      _                   _____          _                   
 | | | |_ __| |__   __ _ _ __   |  ___|_ _  ___| |_ ___  _ __ _   _ 
 | | | | '__| '_ \ / _` | '_ \  | |_ / _` |/ __| __/ _ \| '__| | | |
 | |_| | |  | |_) | (_| | | | | |  _| (_| | (__| || (_) | |  | |_| |
  \___/|_|  |_.__/ \__,_|_| |_| |_|  \__,_|\___|\__\___/|_|   \__, |
                                                              |___/ 
                     Copyright Urban Factory 2017
*/
html, body {
  width: 100%; }

html {
  min-height: 100%;
  background: url("/images/backdrop.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center; }

html, body {
  font-family: 'Montserrat', cursive; }

/*
  _   _      _                   _____          _                   
 | | | |_ __| |__   __ _ _ __   |  ___|_ _  ___| |_ ___  _ __ _   _ 
 | | | | '__| '_ \ / _` | '_ \  | |_ / _` |/ __| __/ _ \| '__| | | |
 | |_| | |  | |_) | (_| | | | | |  _| (_| | (__| || (_) | |  | |_| |
  \___/|_|  |_.__/ \__,_|_| |_| |_|  \__,_|\___|\__\___/|_|   \__, |
                                                              |___/ 
                     Copyright Urban Factory 2017
*/
#container {
  width: 100%;
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(30, 30, 30, 0.78); }
  #container #uf-logo {
    width: 250px;
    margin-bottom: 20px; }
  #container h1 {
    max-width: 972px;
    margin: 0 100px;
    color: white;
    text-align: center;
    font-size: 2.7em;
    font-weight: lighter; }
  #container .social {
    display: flex;
    justify-content: space-between;
    width: 275px;
    min-height: 70px;
    margin-top: 60px; }
    #container .social a {
      height: inherit; }
      #container .social a img {
        height: inherit; }
  #container .company-info {
    width: 400px;
    margin-top: 60px;
    color: white;
    text-align: center;
    font-weight: lighter; }

/*
  _   _      _                   _____          _                   
 | | | |_ __| |__   __ _ _ __   |  ___|_ _  ___| |_ ___  _ __ _   _ 
 | | | | '__| '_ \ / _` | '_ \  | |_ / _` |/ __| __/ _ \| '__| | | |
 | |_| | |  | |_) | (_| | | | | |  _| (_| | (__| || (_) | |  | |_| |
  \___/|_|  |_.__/ \__,_|_| |_| |_|  \__,_|\___|\__\___/|_|   \__, |
                                                              |___/ 
                     Copyright Urban Factory 2017
*/
@media only screen and (max-width: 1160px) {
  #container h1 {
    font-size: 2.2em; } }

@media only screen and (max-width: 980px) {
  #container h1 {
    font-size: 1.95em; } }

@media only screen and (max-width: 920px) {
  #container #uf-logo {
    width: 200px; }
  #container .social {
    width: 250px;
    min-height: 60px;
    height: 60px;
    margin-top: 40px; }
  #container .company-info {
    margin-top: 40px; } }

@media only screen and (max-width: 895px) {
  #container h1 {
    margin: 0 65px; } }

@media only screen and (max-width: 825px) {
  #container h1 {
    font-size: 1.8em; }
  #container .social {
    width: 225px;
    min-height: 50px;
    height: 50px; } }

@media only screen and (max-width: 770px) {
  #container h1 {
    font-size: 1.6em; }
  #container .social {
    width: 200px; } }

@media only screen and (max-width: 700px) {
  #container #uf-logo {
    width: 175px; }
  #container h1 {
    font-size: 1.4em; } }

@media only screen and (max-width: 630px) {
  #container h1 {
    font-size: 1.2em; } }

@media only screen and (max-width: 555px) {
  #container {
    padding: 25px 0; }
    #container h1 {
      margin: 0 15px; }
    #container .company-info {
      width: 300px;
      font-size: 0.8em; } }

@media only screen and (max-width: 455px) {
  #container h1 {
    font-size: 1em; } }
